<template>
  <el-dialog
      center
      :title="title||'提示'"
      :visible.sync="show"
      :before-close="cancel"
      :width="wide">
    <slot></slot>
    <div style="white-space: nowrap;padding: 20px 0;text-align: center" v-show="butShow">
      <el-button :style="{width:butWide}" class="but" @click="cancel">取 消</el-button>
      <el-button :style="{width:butWide}"  class="but" style="background-color: rgba(255,0,77,1)" type="primary" @click="confirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "HiPopup",
  props:{
    show:Boolean,
    title:String,
    wide:{
      type:String,
      default:'480px'
    },
    butWide:{
      type: String,
      default: '188px'
    },
    butShow:Boolean,
  },
  methods:{
    cancel(){
      this.$emit('update:show',false)
      this.$emit('cancel')
    },
    confirm(){
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog{
  padding: 30px 40px 20px 40px;
  border-radius: 8px;
  .el-dialog__header{
    padding: 0;
    .el-dialog__title{
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #222222;
      letter-spacing: 0.01px;
    }
  }
  .el-dialog__body{
    padding: 0;
  }
  .but{
    width: 188px;
    height: 42px;
    background: #f7f7f7;
    border-radius: 3px;
    border:none
  }
}
</style>
