
<template>
    <div class="main">
      <ul class="ulform">
        <li class="ul-from-item">
          <div class="left">
            <img src="@/assets/phone-icon.png">
            <div class="text">
              <div>手机号</div>
              <div>{{getPhone}}</div>
            </div>
          </div>
          <div class="right" @click="phoneShow = true;">修改</div>
        </li>
        <li class="ul-from-item">
          <div class="left">
            <img src="@/assets/login-password-icon.png">
            <div class="text">
              <div>登录密码</div>
              <div></div>
            </div>
          </div>
          <div class="right"  @click="fn">更换</div>
        </li>
        <li class="ul-from-item">
          <div class="left">
            <img src="@/assets/WeChat-icon.png">
            <div class="text">
              <div>微信</div>
              <div>{{isBinding('wxid')}}</div>
            </div>
          </div>
        </li>
        <li class="ul-from-item">
          <div class="left">
            <img src="@/assets/apple-icon.png">
            <div class="text">
              <div>apple</div>
              <div>{{isBinding('apple')}}</div>
            </div>
          </div>
        </li>
      </ul>
      <passwordBox ref="tablebox"></passwordBox>
      <PurPasswordBox ref="istable"></PurPasswordBox>
     <hi-custom-popup wide="480px" :show.sync="phoneShow">
       <div class="content">
          <div v-if="step === 0" class="set-phone-number-step-1">
           <div class="title">更换绑定手机号？</div>
           <div class="subtitle">
             当前绑定的手机号码为 +{{getZone}} {{getPhone}}
           </div>
            <div class="but-footer">
             <el-button  @click="close">取消</el-button>
             <el-button class="but-active" @click="step = 1">更换手机号</el-button>
            </div>
          </div>
         <div v-else-if="step === 1" class="set-phone-number-step-2">
           <div class="title">
             安全验证
           </div>
           <div class="subtitle">短信验证码将发送至手机{{getPhone}}</div>
           <div class="input-box">
             <el-input @keydown.enter.native="smsVerificationFn(paramsStepOne)" v-model="oldCode">
                 <el-button :disabled="oldCountDown !== 60"  type="text" slot="suffix" @click="sendSmsFn(sendSmsVerification)">
                 {{oldCountDown === 60 ? '发送短信验证码' : oldCountDown + 's'}}
               </el-button>
             </el-input>
           </div>
           <div class="but-footer">
             <el-button @click="close">取消</el-button>
             <el-button class="but-active" @click="smsVerificationFn(paramsStepOne)">确定</el-button>
           </div>
         </div>
         <div v-else-if="step === 2" class="set-phone-number-step-3">
           <div class="subtitle">
             请输入未登录过海葵分贝的手机号
           </div>
           <div class="input-box">
             <el-input v-model="newPhone">
               <div slot="prepend">
                 <el-select v-model="newZone">
                   <span slot="prefix">+</span>
                   <el-option v-for="(item, index) in countryPhone" :label="item.tel" :key="index" :value="item.tel"></el-option>
                 </el-select>
               </div>
             </el-input>
           </div>
           <div class="input-box">
             <el-input v-model="newCode" @keydown.enter.native="setPhoneNumber(paramsStepTwo)">
                 <el-button :disabled="newCountDown !== 60" @click="sendSmsFn(newSendSmsVerification)"  type="text"  slot="suffix" >
                   {{newCountDown === 60 ? '发送短信验证码' : newCountDown + 's'}}
               </el-button>
             </el-input>
           </div>
           <div class="but-footer">
             <el-button @click="close">取消</el-button>
             <el-button class="but-active" @click="setPhoneNumber(paramsStepTwo)">确定</el-button>
           </div>
         </div>
         <div v-else-if="step === 3" class="set-phone-number-step-4">
             <div class="title">您输入的手机号 {{this.newPhone}} 已绑定了其他分贝账号，请换号绑定</div>
           <div class="but-footer">
             <el-button class="but-active" @click="close">
               我知道了
             </el-button>
           </div>
         </div>
       </div>
     </hi-custom-popup>
    </div>
</template>

<script>
import passwordBox from '../../components/PasswordBox.vue'
import PurPasswordBox from '../../components/PurPasswordBox.vue'
import HiCustomPopup from '../../components/HiCustomPopup.vue'
import countryPhone from '../../utils/countryPhone'
import request from '@/utils/request'
import {usermsg} from "../../api";
export default {
  name:'AccountSecurity',
  components: {
    HiCustomPopup,
    passwordBox,
    PurPasswordBox,
  },
  computed: {
    userInfo() {
      return this.$store.state.usermsg
    },
    getPhone() {
      let phone = this.userInfo.mobile || ''
      return phone.replace(phone.substring(4,7),'****')
    },
    getZone(){
      return this.userInfo.zone
    },
    getRealPhone(){
      return this.userInfo.mobile
    },
    paramsStepOne(){
      return {
        "zone": this.getZone,
        "phone": this.getRealPhone,
        "type": 4,
        "acode":this.oldCode
      }
    },
    paramsStepTwo(){
      return {
        "zone": this.newZone,
        "phone": this.newPhone,
        "acode":this.newCode
      }
    },
    sendSmsVerification(){
      return {
        zone:this.getZone,
        phone:this.getRealPhone,
        type:4,
        cs:new Date().getTime()
      }
    },
    newSendSmsVerification(){
      return {
        zone:this.newZone,
        phone:this.newPhone,
        type:4,
        cs:new Date().getTime()
      }
    }
  },
  data () {
    return {
      flang: false,
      bankcard: "",
      alterReve: true,
      attestation: '',
      // 个人信息
      data: {},
      identity: '',
      // 银行卡提示
      title: '',
      // 认证结果
      results: '',
      phoneShow:false,//修改手机号,
      alterTitle:{
        0:'提示',
        1:'当前登录手机号验证',
        2:'新手机号绑定',
        3:'提示'
      },
      step:0,
      countryPhone,
      oldCountDown:60,
      newCountDown:60,
      timer1:null,
      timer2:null,
      oldCode:'',
      newZone:'',
      newPhone:'',
      newCode:''
    }
  },

  methods: {
    isBinding(val){
    let i =  (this.userInfo?.appUserAccountList || []).find(item => item.akey === val)
     return  i && i.status === 1 ? i.nickName : '未绑定'
    },
    fn () {
      this.$refs.tablebox.changePassword(this.getPhone, this.data)
    },
    fn1 () {
      this.$refs.istable.changePassword(this.getPhone, this.data)
    },
    close(){
      this.step = 0;
      this.phoneShow = false;
      this.oldCountDown = 60;
      this.newCountDown = 60;
      this.oldCode = '';
      this.newZone ='';
      this.newPhone = '';
      this.newCode = '';
      clearInterval(this.timer1);
      clearInterval(this.timer2);
    },
    sendSmsFn(p){
      this.$post('/api/sms/send',p).then(r => {
        if(r.code === 0){
          if(this.step === 1){
            clearInterval(this.timer1)
            this.oldCountDown --
            this.timer1 = setInterval(()=>{
              if(this.oldCountDown > 0){
                this.oldCountDown --
              }else {
                this.oldCountDown = 60;
                clearInterval(this.timer1)
              }
            },1000)
          }else if(this.step === 2){
            clearInterval(this.timer2)
            this.newCountDown --
            this.timer2 = setInterval(()=>{
              if(this.newCountDown > 0){
                this.newCountDown --
              }else {
                this.newCountDown = 60;
                clearInterval(this.timer2)
              }
            },1000)
          }
        }

      })
    },
    smsVerificationFn(params){
      this.$post('/api/sms/validate', params).then(r => {
       if(r.code === 0){
          if(this.step === 1){
            this.step ++;
          }
       }
      })
    },
    setPhoneNumber(){
      request.post('/user/v2/updateMobile',this.paramsStepTwo).then(r => {
        if(r.code === 0){
          this.$popup({msg:'新手机号绑定成功',title:'提示',type:'success'})
          usermsg().then(r => {
            this.$store.commit('UMSG', r.data)
            this.close()
          })
        }else if(r.code === 501) {
          this.step ++
        }else {
          this.$toast(r.msg)
        }
       })
    }

  },
}
</script>

<style scoped lang="scss">
.main {
  @mixin publicPadding{
    padding: {
      top:37px;
      bottom: 20px;
    };
    .title{
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: LEFT;
      color: #333333;
    }
    .subtitle{
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: LEFT;
      color: #666666;
    }
    .input-box{
      margin-top: 20px;
      ::v-deep .el-input{
        .el-input__inner{
          border: none;
          border-bottom: 1px solid #e8e8e8;
        }
        .el-input__suffix{
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #ff004d;
          cursor: pointer;
        }
      }

    }
  }
  .ulform {
   padding: 0;
    list-style: none;
  .ul-from-item{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    .left {
      display: flex;
      align-items: center;
      width: 300px;
      >img{
        margin-right: 16px;
        width: 48px;
        height: 48px;
      }
      .text{
        div:first-child{
          margin-bottom: 8px;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
        }
        div{
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #666666;
        }
      }
    }
    .right{
      font-size: 14px;
      font-weight: 400;
      text-align: LEFT;
      color: #ff004d;
      cursor: pointer;
    }
  }
  }
  .content{
    .but-footer{
      text-align: center;
      margin-top: 37px;
      .el-button{
        width: 160px;
        height: 40px;
        background: #f4f4f4;
        border-radius: 3px;
        margin: 0 9px;
        &.but-active{
          background: #ff004d;
          color: #ffffff;
        }
      }
    }
    .set-phone-number-step-1{
      @include publicPadding
    }
    .set-phone-number-step-2{
      @include publicPadding
    }
    .set-phone-number-step-3{
      @include publicPadding;
      .subtitle{
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
      }
      .input-box{
         ::v-deep .el-input-group__prepend{
            background: #FFFFFF;
           border: none;
           border-bottom: 1px solid #e8e8e8;
         }
        ::v-deep .el-select{
          width: 85px;
          box-sizing: border-box;
          .el-input__inner{
            padding: 0 15px;
            box-sizing: border-box;
            border: none;
          }
         .el-input__prefix{
           display: flex;
           align-items: center;
         }
        }
      }
    }
    .set-phone-number-step-4{
      .title{
        margin: 34px 0;
        width: 360px;
        height: 51px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #333333;
        line-height: 24px;
      }
    }
  }
}
</style>
