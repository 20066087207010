import request from "../utils/request"
import toast from "../utils/toast"
import router from '../router/index'

/**
 * get和post二次封装，统一处理错误
 * 如果需要处理错误请求，直接引用request.js
 */
export const $get = (url, config = {}) => {
  return new Promise((resolve) => {
    request.get(url, config).then(
      (data) => {
        // 其他错误
        if (data.code && data.code !== 0) {
          toast(data.msg || '请求失败')
          //   判断用户是否中途退出班级
          if (data.code === 1101) {
            router.replace('/')
          }
          // return Promise.reject(data)
        }
        resolve(data)
      },
      () => {}
    )
  })
}
/**
 *
 * @param url 请求地址
 * @param data 请求数据
 * @param config 请求配置
 * @returns {Promise<unknown>}
 */
export const $post = (url, data = {}, config = {}) => {
  return new Promise((resolve) => {
    request.post(url, data, config).then(
      (data) => {
        // 其他错误
        if (data.code && data.code !== 0) {
          toast(data.msg || '请求失败')
          //   判断用户是否中途退出班级
          if (data.code === 1101) {
            router.replace('/')
          }
          // return Promise.reject(data)
        }
        resolve(data)
      },
      () => {}
    )
  })
}

const getContractById = (id) => {
  return $get('/api/demo/contract', { params: { songId: id } })
}

const download = (url, resOpts = {}) => {
  const { type = 'get', data = '' } = resOpts
  const queryArgs = {
    url,
    method: type,
    data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8'
    }
  }
  // tips: 这里直接返回的是response整体!
  return request(queryArgs).catch((err) => console.log(err))
}

const getPublishedList = (data) => {
  if (data) {
    return $post('/api/user/musicianSongs', data)
  } else {
    return $get('/mock/publishedList.json', { baseURL: '/' })
  }
}
const getPublishedView = (params) => {
  return $get('/api/song/detail', { params })
}

export default {
  getContractById,
  download,
  getPublishedList,
  getPublishedView,
  $post,
  $get
}

// 发行详情页 入账明细
export const getrelease = (params) => {
  return $post('/api/wallet/billRecordDetail', { params })
}
// 字典
export const dictInfo = (params) => {
    return $post('/api/dict/dictInfo',params)
}

//咨讯
// 首页资讯(精选资讯)
export const homePage = (params) => {
    return $post('/api/news/index',params)
}
//新闻资讯列表
export const newsList = (params) => {
    return $post('/api/news/list',params)
}
//资讯详情
export const  newsdetail = (params) => {
    return $post('/api/news/detail?id='+params.id)
}
// 活动
// 首页活动列表
export const activity=(params)=>{
    return $post('/api/activity/index',params)
}
//所有活动列表
export const activity_list=(params)=>{
    return $post('/api/activity/list', params)
}
// 我的活动
export const activity_mylist=(parmas)=>{
    return $post('/api/activity/myList', parmas)
}
// 获取给个人数据
export const usermsg=(params)=>{
    return $post('/api/user/userMsg',params)
}
// 活动详情
export const activity_detail=(params)=>{
    return $post('/api/activity/detail?id='+params)
}
// 获取我的歌曲
export const activity_mySongs=(params)=>{
    return $post('/api/activity/mySongs',params)
}
//获取我的词曲
export const activity_mydemo=(params)=>{
    return $post('/api/activity/myDemo',params)
}
// 活动投稿
export const activity_submit=(params)=>{
    return $post('/api/activity/submit',params)
}
//短信
// 发送短信
export const sms_send=(params)=>{
    return $post('/api/sms/send',params)
}
//短信校验
export const sms_validate=(params)=>{
    return $post('/api/sms/validate',params)
}

// 钱包
// 待收益账单
export const wallet_incomeList = () => {
  return $post('api/wallet/incomeList', {
    status: 0,
    pageSize: 10000000,
    pageNum: 1
  })
}
export const wallet_incomeList2 = (params) => {
  return $post('api/wallet/incomeList', params)
}
// 确定收益
export const wallet_incomeDetailCheck = (params) => {
  return $post('/api/wallet/incomeDetailCheck?ids=' + params)
}
// 账单详情
export const wallet_incomeDetail = (params = {}) => {
  return $post('/api/wallet/incomeDetail', params)
}


//提现记录
export const cashOutRecord=(params)=>{
  return $post('/api/wallet/cashOutRecord',params)
}

//待提现的账单列表
export const paymentCompanyData=(params = {})=>{
  return $post('/api/wallet/paymentCompanyData',params)
}

//入账记录
export const incomeList=(params = {})=>{
  return $post('/api/wallet/incomeList',params)
}

//提现详情
export const cashOutRecordDetail=(params = {})=>{
  return $post(`/api/wallet/cashOutRecordDetail`,params)
}

//钱包-提现-账单统计数据
export const statisticsByCompany=(params = {})=>{
  return $post(`/api/wallet/statisticsByCompany`,params)
}
// 银行卡信息
export const wallet_myBankMsg=(params)=>{
    return $post('/api/wallet/myBankMsg',params)
}

/**
 * 帮助中心
 */

export const helpText_type=(params)=>{
    return $post('/api/helpText/type',params)
}
export const helpText_text=(params)=>{
    return $post('/api/helpText/text?id='+params)
}

//待办事项
 export const todoListTop =(params) =>{
    return $post('/api/todoList/top',params)
 }

/**
 * 版权 获取艺人
 * @param data
 * @returns {Promise<*>}
 */
export function getSinger(data){
  return $post(`/api/issue/universal/singer/queryTME`, data)
}

/**
 * 版权 提交发行专辑
 * @param data
 * @returns {Promise<*>}
 */
export function createAlbum(data){
  return $post(`/api/issue/album/${data.album.album_id === 0 ? 'create' : 'update'}`, data)
}


/**
 * 版权 歌词解析
 * @param data
 * @returns {Promise<*>}
 */
export function lyricParse(data){
  return $post(`/api/issue/universal/song/lyricParse`, data)
}

/**
 * 用户的机构列表
 * @param type 是否返回审核失败/审核中机构, 0 否 1是,默认0
 * @returns {Promise<*>}
 */
export function getCompanyList(type = 0){
  return $post('/api/user/companyList', { type })
}

